@font-face {
  font-family: 'Satoshi-Regular';
  src: local('Satoshi-Regular'), url(../fonts/Satoshi-Regular.ttf) format('truetype');
}

@font-face {
  font-family: 'Satoshi-Bold';
  src: local('Satoshi-Bold'), url(../fonts/Satoshi-Bold.ttf) format('truetype');
}

@font-face {
  font-family: 'Roboto-Regular';
  src: local('Roboto-Regular'), url(../fonts/Roboto-Regular.ttf) format('truetype');
}
@font-face {
  font-family: 'Orbitron-Regular';
  src: local('Orbitron-Regular'), url(../fonts/Orbitron-Regular.ttf) format('truetype');
}

@font-face {
  font-family: 'Orbitron-Bold';
  src: local('Orbitron-Bold'), url(../fonts/Orbitron-Bold.ttf) format('truetype');
}

@font-face {
  font-family: 'Montserrat-Regular';
  src: local('Montserrat-Regular'), url(../fonts/Montserrat-Regular.ttf) format('truetype');
}

:root {
  --main-font-regular: "Montserrat-Regular";
  --main-font-bold: "Orbitron-Bold";
  --roboto-font-regular: "Orbitron-Regular";
  --nav-bg-color: #FBFCFF;
  --primaryFontColor: #EFF3FE;
  --secondaryFontColor: #1D1D1D;
  --primaryBtnBg: #1B487F;
  --secondaryBtnBg: #fff;
  --backgroundGrey: #D9D9D9;
  --backgroundWhite: #FFF;
  --backgroundGreen: #0D333F;
  --backgroundLightGrey: #F8F8F8;
  --backgroundFooter: #242729;
  --nav-menu-li-color: #535A65;
  --backgroundLightBlue: #E4F3FF;
  --backgroundLightYellow: #FFF5C2;
  --backgroundFooter: #242729;
  --checkboxBgChecked: #3182CE;
  --checkboxBg: #E2E8F0;
  --inner-page-bg-main: #F1F5F9;
  --divider-color: #CED0D9;
  --green-color: #2CA01C;
}