.app-layout {
    background: #fff;
}

.section-padding {
    padding: 50px 120px;
}

.no-border-radius {
    border-radius: 0;
}

.primaryBtn {
    height: 40px;
    padding: 10px 16px;
    border-radius: 6px;
    background: var(--primaryBtnBg);
    color: #fff;
    font-size: 16px;
    font-style: normal;
    font-weight: 600;
}

.secondaryBtn {
    height: 40px;
    padding: 10px 16px;
    border-radius: 6px;
    background: var(--secondaryBtnBg);
    color: #0D333F;
    font-size: 16px;
    font-style: normal;
    font-weight: 600;
}

.full-width-img {
    width: 100%;
}

.inner-page-main {
    padding: 32px 50px;
    background: var(--inner-page-bg-main);
    min-height: calc(100vh - 268px);
}

.full-width-input {
    width: 100%;
}

.mt-8 {
    margin-top: 8px;
}

.mt-15 {
    margin-top: 15px;
}

.mt-16 {
    margin-top: 16px;
}

.mt-18 {
    margin-top: 16px;
}

.mt-22 {
    margin-top: 22px;
}

.divider {
    margin: 15px 0;
    border-block-start: 1px solid var(--divider-color);
}

.app-checkbox .ant-checkbox-inner {
    height: 20px;
    width: 20px;
    border-radius: 2px;
    border: 2px solid var(--checkboxBg);
    background: #fff;
}

.app-checkbox .ant-checkbox-inner:hover {
    border: 2px solid var(--checkboxBgChecked);
}

.app-checkbox .ant-checkbox-checked .ant-checkbox-inner {
    background: var(--checkboxBgChecked);
    border: 2px solid var(--checkboxBgChecked);
}

.app-footer-container {
    padding: 0;
}

.college-logo {
    height: 40px;
    width: 40px;
}

.college-logo-alternate {
    height: 40px;
    width: 40px;
    color: #fff;
    background-color: #f56a00;
    text-transform: uppercase;
}

.college-logo-alternate-single {
    height: 98px;
    width: 98px;
    color: #fff;
    background-color: #f56a00;
    text-transform: uppercase;
    font-size: 25px;
    font-weight: 700;
}

.avatar-logo-stacked {
    border-radius: 50px;
    border: 1px solid #ccc;
    height: 32px;
    width: 32px;
}

@media only screen and (max-width: 500px) {
    .hide-for-mobile {
        display: none !important;
    }
}

@media only screen and (max-width: 767px) {

    .travel-insurance-action,
    .explore-premium-action {
        align-items: flex-start !important;
        justify-content: flex-start !important;
    }
}

@media only screen and (max-width: 1150px) {
    .section-padding {
        padding: 50px;
    }

    .top-university {
        padding: 6px 12px !important;
    }
}

@media only screen and (max-width: 600px) {}

@media only screen and (max-width: 1000px) {
    .hide-country-name {
        display: none;
    }
}