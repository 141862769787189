body {
  margin: 0;
}

.scrollable-container-without-scrollbar {
  overflow: auto;
  /* Allow scrolling */
  scrollbar-width: none;
  /* For Firefox */
  -ms-overflow-style: none;
  /* For Internet Explorer and Edge */
}

.scrollable-container-without-scrollbar::-webkit-scrollbar {
  display: none;
  /* For Chrome, Safari, and newer versions of Edge */
}

.university-card {
  opacity: 0;
  transition: opacity 0.5s ease-in-out;
}

.university-card.visible {
  opacity: 1;
}

.university-tabs {
  width: 100%;
}